.discover {
  display: flex;
  flex-direction: row;
  /* max-width: fit-content; */
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 6.5em;
  margin-bottom: 20px;
  background-color: aliceblue;
  padding: 40px;
  border-radius: 20px;
}

.reverse {
  display: flex;
  flex-direction: row;
  /* max-width: fit-content; */
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 6.5em;
  margin-bottom: 20px;
  margin-left: 30px;
  background-color: aliceblue;
  padding: 40px;
  border-radius: 20px;
}

.title.discover-title {
  margin: 0;
  font-size: 20px;
  margin: 20px;
  font-family: 'Figtree', sans-serif;
  
}

@media screen and (max-width: 768px) {
  .discover {
    flex-direction: column;
    margin-bottom: 60px;
    background-color: unset;
  }

  .discover h1 {
    width: 80%;
  }

  .title.discover-title {
    font-size: 1.2em;
  }
}