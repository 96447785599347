.bg-image {
  width: 30vw;
  height: 40vw;
  background-image: url('../../assets/kitchen-min.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  opacity: 0.7;
  z-index: 5;
}

.button-container {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin-bottom: 50px;
}

.infoBody {
  background-color: #fbfaf8;
}

.info {
  text-align: center;
  padding: 3rem 0;
  background-color: #d6b99a;
}


.content-container-two {
  display: flex;
  flex-direction: row;
  padding: 40px 100px;
  /* margin-bottom: 50px; */
  background-color: #ffffff;
}

.imgContainer-two {
  width: 40vw;
}

.value-prop-summary {
  /* margin-bottom: 30px; */
  text-transform: none;
  font-weight: lighter;
  letter-spacing: .1rem;
  line-height: 1.75rem;
  font-size: 1.2em;
}

.textContainer-two {
  /* TODO: set to 50% down from top */
  width: 50vw;
  margin-top: 40px;
}

.title-two {
  font-size: 2.75rem;
  margin-bottom: 30px;
  /* margin-left: -5rem; */
  text-transform: none;
  font-family: 'Frank Ruhl Libre', serif;
  font-weight: 200;
}

@media screen and (max-width: 768px) {
  .bg-image {
    width: 75vw;
    height: 100vw;
  }

  .button-container {
    margin-left: 0;
  }

  .content-container-two {
      /* display: block; */
      flex-direction: column;
      padding: 30px;
      margin-bottom: 0px;
      justify-content: center;
      align-items: center;
    }

  .imgContainer-two {
    width: 75vw;
  }

  .info {
    padding: 1.5rem;
  }

  .title-two {
    font-size: 2rem;
    margin: 1rem 0;
  }

  .textContainer-two {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80vw;
    margin-top: 30px;
  }

  .value-prop-summary {
    font-size: 1em;
  }

}
