.cta-button {
  width: 300px;
  height: 60px;
  border-radius: 10px;
  background-color: #c7a887;
  border: solid 0px #c7a887;
  color: #ffffff;
  font-size: 1.2em;
}

.cta-button:hover {
  background-color: #fbfaf8;
  transition: .5s;
  cursor: pointer;
  border-width: 3px;
  color: #000000;
}

@media screen and (max-width: 768px) {
  .cta-button {
      margin: 0;
    }
}