.nutrigenomix-logo {
  width: 70vw;
}
.testing-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 60vw;
}

.testing-button-container article {
  margin: 10px;
}

.textContainer-three {
  /* TODO: set to 50% down from top */
  width: 60vw;
  margin-top: 40px;
}

@media screen and (max-width: 768px) {
  .nutrigenomix-logo {
    width: 90vw;;
  }

  .testing-button-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .testing-button-container article {
    margin: 10px 0;
  }
}