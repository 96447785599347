@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;700&display=swap');

* {
  font-family: 'Figtree', sans-serif;
  text-transform: uppercase;
}
h1 {
  font-size: 1.2em;
  font-weight: lighter;
  margin: auto;
}
/* a {
  color: black;
  text-decoration: none;
} */
.guide-text a {
  display: flex;
  padding: 5px 5px;
  text-align: center;
  background-color: #d6b99a;
  font-weight: lighter;
  height: 40px;
  text-decoration: none;
  color: #000000;
  width: 100vw;
}

/* .link-text {
  font-weight: 700;
} */
@media screen and (max-width: 768px) {
  h1 {
    font-size: 14px;
  }
}