.small-hero {
  width: 100vw;
  height: 300px;
  background-image: url('../../assets/smallhero.jpg');
  background-position: center;
  opacity: .7;
}

@media screen and (max-width: 768px) {
  .small-hero {
    height: 150px;
  }
}