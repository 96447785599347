.content-container {
  display: flex;
  flex-direction: row;
  padding: 100px
}

.imgContainer {
  width: 50vw;
}

.main-image {
  width: 30vw;
}

.textContainer {
  width: 50vw;
}

.title {
  font-size: 3rem;
  margin-bottom: 30px;
  margin-left: -5rem;
  text-transform: none;
  font-family: 'Frank Ruhl Libre', serif;
}

.title-two.about {
  text-transform: capitalize;
}

.bio-item {
  margin-bottom: 30px;
  text-transform: none;
  font-weight: lighter;
  letter-spacing: .1rem;
  line-height: 1.75rem;
  font-size: 1.2em;
}

.shadow
{
    display:block;
    position:relative;
}

.shadow:before
{
    display:block;
    content:'';
    position:absolute;
    width:30vw;
    height:100%;
    -moz-box-shadow:inset 0px 0px 6px 6px rgba(255,255,255,1);
    -webkit-box-shadow:inset 0px 0px 6px 6px rgba(255,255,255,1);
    box-shadow:inset 0px 0px 6px 6px rgba(255,255,255,1);
}

.video-container iframe {
  margin-top: 2rem;
  width: 100%;
  /* height: 315px; */
}

@media screen and (max-width: 768px) {
  .bio-item {
    font-size: 1em;
  }

  .content-container {
    flex-direction: column;
    padding: 0px;
    margin-top: 50px;
    justify-content: center;
  }

  .imgContainer {
    width: 90vw;
    margin: 20px;
  }
  .main-image {
    width: 80%;
    
  }

  .shadow:before {
    width: 75vw;
  }

  .textContainer {
    width: 90vw;
    margin: 20px;
    padding: 0px;
  }

  .textContainer ul li {
    margin-left: 20px;
  }

  .title {
    font-size: 1.5rem;
    margin-left: 0;
  }

  /* .video-container {
    margin-top: 2rem;
    width: unset;
    height: unset;
} */
}