.contact-form {
  margin: 30px 0 30px 0;
  width: 70vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: gray solid .5px;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 5px 5px 5px 5px #b3acac;
}

.contact-form div {
  margin-bottom: 20px;
}

.contact-form div input, textarea {
  width: 60vw;
  height: 50px;
  margin: 5px 0 0 0;
  border: #b3acac solid .5px;
  border-radius: 20px;
  background-color: rgb(237, 234, 234);
  padding: 5px;
  text-transform: none;
}

.contact-form div textarea {
  height: 100px;
}

.contact-form div p {
  text-transform: none;
}

.contact-form div p span, .error-text {
  color: red;
}

@media screen and (max-width: 768px) {
  .contact-form {
    width: 90vw;
    box-shadow: none;
  }
  .contact-form div input, textarea {
    width: 80vw;
  }


}
