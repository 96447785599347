.title-two.blog {
  margin: 0;
  text-transform: uppercase;
  /* font-size: 30px; */
  font-family: 'Frank Ruhl Libre', serif;
  text-transform: none;
  font-weight: 400;
  margin-bottom: 20px;
}

.blog-thumbnail {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90vw;
  /* min-height: 200px; */
  padding: 1rem;
  margin: 20px 0;
  /* border: solid black; */
  background-color: #f8f8f8;
}

.blog-link {
  text-decoration: none;
  color: black;
}

.blog-thumbnail.left {
  flex-direction: row-reverse;
}

.blog-thumbnail section {
  margin-right: 20px;
}

.blog-thumbnail.left section {
  margin-left: 20px
}

.blog-thumbnail img {
  width: 20vw;
  height: 20vw;
}

.learn-more {
  margin-top: 20px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .blog-thumbnail {
    flex-direction: column;
    justify-content: center;
  }

  .blog-thumbnail.left {
    flex-direction: column;
    justify-content: center;
  }

  .blog-thumbnail img {
    width: 80vw;
    height: 80vw;
  }

  .blog-thumbnail section p {
    margin: 10px 0;
  }

  .blog-thumbnail.left section {
    margin: 10px 0px;
    padding: 0;
  }
}