.wwm-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding-top: 40px;
  margin: 0;
}

.technical-stuff {
  display: block;
  width: 70%;
}

.technical-stuff img {
  width: 70vw;
  margin-bottom: 20px;
}

.fig-img img {
  width: 350px;
}

.wwm-section {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 30px 0;
}

.wwm-section-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;
  margin: 30px 0;
}

.wwm-section-flex-other {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin: 30px 0;
}

.wwm-section-flex-with-img {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  margin: 30px 0;
}

.wwm-section-flex article,
.wwm-section-flex-with-img article,
.wwm-section-flex-other article
{
  margin: 0 20px
}

.wwm-section-flex-with-img article {
  margin: 0 20px
}

.wwm-section-flex .value-prop-summary {
  margin: 0;
}

.wwm-section-flex-with-img .value-prop-summary {
  margin: 0;
}

.wwm-section div .title-two {
  font-size: 40px;
}

.title-three {
  font-size:30px;
  margin-left: -40px;
}

.title-four {
  font-weight: bold;
}

.no-transform {
  text-transform: none;
}
/* .small-section {
  width: 70%;
} */

.small-section .value-prop-summary {
  margin-bottom: 0;
  padding: 5px;
}

.disc-div {
  margin-left: -40px;
}

@media screen and (max-width: 768px) {
  .technical-stuff {
    width: 90vw;
  }

  .technical-stuff img {
    width: 100%;
  }

  .wwm-section-flex-with-img, .wwm-section-flex, .wwm-section {
    display: block;
    width: 90%;
  }

  .wwm-section-flex article {
    margin-bottom: 30px;
  }

  .wwm-section .value-prop-summary {
    margin: 0 0 10px 0;
  }

  .small-section .title-three {
    font-size: 25px;
  }

  .technical-stuff .title {
    font-size: 40px;
  }

}