.retreat-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin: 30px 0;
  padding-top: 30px;
}

.retreat-container img {
  width: 75%;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .retreat-container img {
      width: 90%;
      margin-bottom: 20px;
    }
  .retreat-container h2 {
    text-align: center;
  }
}